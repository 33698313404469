import { defineStore } from "pinia";

import { useAdminAuthStore as authStore } from "@/stores/_admin/auth_admin.store";
import { useNotificationStore as notificationStore } from "@/stores/notification.store";
import {
  customError,
  arrayHasChanged,
  redirectToPreviousPage,
  convertRoleNamesToIDs,
  convertRoleIDsToNames,
} from "@/helpers";
import { useNavStore as navStore, useNavStore } from "@/stores/nav.store";
import axiosInstance from "@/helpers/axios";
import { useAuthStore, useMetaStore } from "@/stores";

export const useAdminUsersStore = defineStore({
  id: "admin-users",
  state: () => ({
    users: [],
    migrate_users: [],
    users_detail: {},
    visibility_restricted_group_options: [],
    loading: false,
  }),
  getters: {},
  actions: {
    isSuperAdmin(ids) {
      const roles = convertRoleIDsToNames(ids);
      return roles?.is_provider_admin;
    },
    async createUser(formData) {
      const { first_name, last_name, email, roles, provider } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/admin/users`, {
          first_name,
          last_name,
          email,
          provider: this.isSuperAdmin(roles) ? provider?.id : "",
          ...convertRoleIDsToNames(roles),
        });

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("admin-users");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateUser(formData) {
      const { roles: tempRoles } = formData.tempForm;
      const { user_id, first_name, last_name, email, roles, provider } = formData.form;
      useNavStore().setAppSpinner(true);
      try {
        const rolesChanged = arrayHasChanged(roles, tempRoles);

        const user = await axiosInstance.put(`/admin/users/${user_id}`, {
          first_name,
          last_name,
          email,
          provider: this.isSuperAdmin(roles) ? provider?.id : "",
          ...convertRoleIDsToNames(roles),
        });

        // update permissions if roles changes
        if (rolesChanged) {
          await authStore().refreshPermissions();
        }
        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("admin-users");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteOrMigrateUser(data) {
      const { user_id, type, migrate_user_id } = data;
      const authUser = { ...authStore().getUser };

      let options = {};
      if (type === "migrate" && migrate_user_id) {
        options.migrate_user_id = migrate_user_id;
      }

      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.delete(`/admin/users/${user_id}`, {
          data: options,
        });

        notificationStore().saveAlert(user.data.message);
        if (authUser?.id && authUser.id === user_id) {
          await authStore().logout();
        } else {
          await redirectToPreviousPage("admin-users");
        }
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getUserDetails(user_id) {
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.get(`/admin/users/${user_id}`);

        const user_data = user.data.data;

        // update pinia state
        this.users_detail = {
          user_id,
          page_name: `${user_data.first_name} ${user_data.last_name}`,
          first_name: user_data.first_name,
          last_name: user_data.last_name,
          email: user_data.email,
          provider: user_data.provider,
          roles: convertRoleNamesToIDs(user_data),
        };
      } catch (error) {
        this.users_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    resetUserDetails() {
      this.users_detail = {};
    },
    async getUsers({ limit }) {
      this.loading = true;
      useNavStore().setAppSpinner(true);
      try {
        const users = await axiosInstance.get(`/admin/users`, {
          params: {
            Pagination: false,
          },
        });

        // update pinia state
        this.users = users.data.data;

        if (limit) {
          this.users = this.users.slice(0, limit);
        }
      } catch (error) {
        this.users = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
