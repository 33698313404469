import { defineStore } from "pinia";

import { useAdminAuthStore as authStore } from "@/stores/_admin/auth_admin.store";
import { useNotificationStore as notificationStore } from "@/stores/notification.store";
import { customError, router } from "@/helpers";
import { useNavStore as navStore, useNavStore } from "@/stores/nav.store";
import axiosInstance from "@/helpers/axios";
import { useAuthStore } from "@/stores";
export const useAdminImpersonateStore = defineStore({
  id: "admin_impersonate",
  state: () => ({
    customers: [],
    customers_detail: {},
    loading: false,
    loading_detail: false,
    impersonate: {},
  }),
  getters: {},
  actions: {
    async logoutImpersonate() {
      useNavStore().setAppSpinner(true);

      try {
        await router.push({ name: "impersonate-leave" });
        await axiosInstance.post(`/auth/impersonate/leave`);
      } catch (error) {
        throw customError(error);
      } finally {
        setTimeout(() => {
          const returnUrl = useAuthStore().authImpersonateLogin?.returnUrl;

          router.push(returnUrl ? returnUrl : { name: "admin-dashboard" });
          useAuthStore().restoreForImpersonate();
          useNavStore().setAppSpinner(false);
        }, 800);
      }
    },
    async loginImpersonate({ customer_id, user_id }) {
      useNavStore().setAppSpinner(true);
      try {
        const impersonate = await axiosInstance.post(
          `/admin/impersonate/customers/${customer_id}/users/${user_id}/take`
        );
        // update pinia state
        const data = impersonate.data.data;
        this.impersonate = data;
        if (data) {
          const returnUrl = navStore().getReturnUrl.to;
          useAuthStore().backupForImpersonate({ user_id, returnUrl });
          useAuthStore().setAuth(data);
        }
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async activateUser({ user_id }) {
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/admin/users/${user_id}/verify`);
        notificationStore().saveAlert(user.data.message);

        // update verified by user id
        this.customers_detail.users = this.customers_detail.users.map((user) =>
          user.id === user_id ? { ...user, verified: true } : user
        );
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getCustomerDetails({ customer_id, term }) {
      this.loading_detail = true;
      useNavStore().setAppSpinner(true);
      try {
        let params = {
          Pagination: false,
        };

        if (term) {
          params = {
            search: term,
            ...params,
          };
        }

        const customer = await axiosInstance.get(`/admin/impersonate/customers/${customer_id}/users`, {
          params,
        });

        const customer_data = customer.data.data;
        // update pinia state
        this.customers_detail = customer_data;
      } catch (error) {
        this.customers_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        this.loading_detail = false;
        useNavStore().setAppSpinner(false);
      }
    },
    resetCustomerDetails() {
      this.customers_detail = {};
    },
    async getCustomers({ term, limit }) {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        let params = {
          Pagination: false,
        };

        if (term) {
          params = {
            search: term,
            ...params,
          };
        }

        const customers = await axiosInstance.get(`/admin/impersonate/customers`, {
          params,
        });

        // update pinia state
        this.customers = customers.data.data;

        if (limit) {
          this.customers = this.customers.slice(0, limit);
        }
      } catch (error) {
        this.users = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
