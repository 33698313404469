import { useNavStore, useAppStore, useAuthStore, useAdminAuthStore } from "@/stores";

export function getApiHeaders(config = {}) {
  config = {
    ...config,
  };

  let headers = {
    Accept: "application/json",
  };

  const isAdmin = useNavStore().isAdminPage;
  const token = isAdmin ? useAdminAuthStore().getToken : useAuthStore().getToken;
  const user = isAdmin ? useAdminAuthStore().getUser : useAuthStore().getUser;

  if (token) {
    headers = {
      ...headers,
      Authorization: token,
    };
  }

  const provider = useAppStore().getAppConfig.providerID; // get provider from appConfig
  if (provider) {
    headers = {
      ...headers,
      "X-Domain-Provider": provider,
    };
  }

  // Check by URL
  if (config?.url) {
    const isLogoutUrl = config.url.startsWith(`${isAdmin ? "/admin" : ""}/auth/logout`);
    if (isLogoutUrl) {
      return headers;
    }
  }

  // Admin
  if (isAdmin) {
    const adminProvider =
      user?.provider && user.provider?.id ? user.provider.id : useAppStore().getAppConfig.providerID; // get provider id from store, otherside get from appConfig
    if (adminProvider) {
      headers = {
        ...headers,
        "X-BCM-Provider": adminProvider,
      };
    }
  } else {
    // Platform

    const profile = useAuthStore().getCustomerProfile;
    const customer = profile?.customer?.id;
    const region = profile?.region?.id;

    if (customer) {
      headers = {
        ...headers,
        "X-BCM-Customer": customer,
      };
    }

    if (region) {
      headers = {
        ...headers,
        "X-BCM-Region": region,
      };
    }
  }

  return headers;
}
