<template>
  <div class="view-body">
    <div class="content-wrapper">
      <NotificationMessage type="404" v-if="notFound" />
      <template v-else>
        <PageTitle :title="title" :backUI="true" :backUITo="backUITo" />
        <TabNav
          :items="[
            {
              title: $t('label.users'),
            },
          ]"
          variant="tab"
        >
          <template #controls>
            <div class="flex justify-content-end gap-5 align-items-center">
              <OpenImpersonateLogin />
              <form id="search" @submit.prevent="onSearch" class="flex align-items-center">
                <FormText
                  type="search"
                  size="lg"
                  :minlength="2"
                  v-model="searchTerm"
                  :placeholder="$t('placeholder.search_by_term')"
                />
                <ButtonUI
                  v-if="term"
                  :label="$t('button.reset')"
                  :underline="true"
                  variant="text"
                  size="lg"
                  color="text-palette-1"
                  @click="resetSearch()"
                />
              </form>
            </div>
          </template>

          <template #panels>
            <NotificationMessage :error="error" />
            <div v-if="!loading_detail">
              <TableUI v-if="customers_detail.users.length > 0" name="users" :value="customers_detail.users">
                <template #columns>
                  <PColumn field="name" :header="$t('label.name')" :style="getCellWidth('name')" />
                  <PColumn field="roles" :header="$t('label.roles')" :style="getCellWidth('roles')">
                    <template #body="slotProps">
                      <span v-for="(role, index) in slotProps.data.roles" :key="role.id">
                        <span v-if="index !== 0">, </span>
                        {{ $t(`roles.${role.name}`) }}
                      </span>
                    </template>
                  </PColumn>

                  <PColumn field="state" :style="getCellWidth('state')" :header="$t('label.state')">
                    <template #body="slotProps">
                      <span
                        class="status status--online"
                        v-if="impersonateIsActive(slotProps.data.id)"
                        v-html="$t('label.impersonate_active')"
                      />
                    </template>
                  </PColumn>

                  <!-- Render controls column -->
                  <PColumn
                    headerStyle="text-align: right"
                    bodyStyle="text-align: right; overflow: visible"
                    :style="getCellWidth('controls')"
                  >
                    <template #body="slotProps">
                      <div class="controls" v-if="getPermissions('impersonate users')">
                        <ButtonUI
                          v-if="!slotProps.data.verified"
                          :label="$t('button.activate_user')"
                          variant="text"
                          color="text-palette-1"
                          feather="user-check"
                          class="p-button-text-inline"
                          @click="activateUser(slotProps.data.id)"
                        />
                        <ButtonUI
                          :label="$t('button.login_as_user')"
                          variant="text"
                          color="text-palette-1"
                          feather="monitor"
                          class="p-button-text-inline"
                          @click="loginAsUser(slotProps.data.id)"
                        />
                      </div>
                    </template>
                  </PColumn>
                </template>
              </TableUI>
              <NotificationMessage type="default" v-else>{{ $t("message.no_data_found") }}</NotificationMessage>
            </div>
          </template>
        </TabNav>
      </template>
    </div>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PageTitle from "@/components/PageTitle.vue";

import { useRoute } from "vue-router";
import { useAdminImpersonateStore, useAuthStore, useNavStore } from "@/stores";
import { storeToRefs } from "pinia";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { getPermissions, getTableCellWidth, router, setBreadcrumbs } from "@/helpers";
import TableUI from "@/components/TableUI.vue";
import PColumn from "primevue/column";
import ButtonUI from "@/components/ButtonUI.vue";
import FormText from "@/components/form/FormText.vue";
import OpenImpersonateLogin from "@/components/_admin/OpenImpersonateLogin.vue";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { customer_id } = route.params;

    const impersonateStore = useAdminImpersonateStore();

    const { customers_detail, loading_detail } = storeToRefs(impersonateStore);

    const searchTerm = ref(null);
    const error = ref(null);
    const handleTimeout = ref(false);

    const term = computed(() => {
      return route.query.uterm;
    });

    const backUITo = computed(() => {
      return {
        name: "admin-impersonate",
        query: {
          page: route?.query?.page ?? null,
          cterm: route?.query?.cterm ?? null,
        },
      };
    });

    onMounted(() => {
      searchTerm.value = term.value;
    });
    onUnmounted(() => {
      impersonateStore.resetCustomerDetails();
      searchTerm.value = null;
    });

    watch(
      term,
      (value) => {
        impersonateStore.getCustomerDetails({ customer_id, term: value });
      },
      {
        immediate: true,
      }
    );

    return { term, backUITo, searchTerm, customer_id, customers_detail, loading_detail, error, handleTimeout };
  },
  components: {
    OpenImpersonateLogin,
    FormText,
    ButtonUI,
    PColumn,
    TableUI,
    TabNav,
    PageTitle,
    NotificationMessage,
  },
  methods: {
    async activateUser(user_id) {
      if (this.handleTimeout) {
        clearTimeout(this.handleTimeout);
      }
      useNavStore().setAppSpinner(!!user_id);
      this.handleTimeout = setTimeout(() => {
        if (user_id) {
          useAdminImpersonateStore()
            .activateUser({ user_id })
            .catch((error) => {
              this.error = error;
            });
        }
      }, 200);
    },
    async loginAsUser(user_id) {
      if (this.customer_id && user_id) {
        return useAdminImpersonateStore()
          .loginImpersonate({ customer_id: this.customer_id, user_id })
          .catch((error) => {
            this.error = error;
          });
      }
    },
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("users", cell);
    },
    impersonateIsActive(user_id) {
      return useAuthStore().authImpersonateLogin?.user_id === user_id;
    },
    async resetSearch() {
      this.searchTerm = null;
      await router.push({
        query: {
          page: this.$route?.page ?? null,
          cterm: this.$route?.query?.cterm ?? null,
          uterm: null,
        },
      });
    },
    async onSearch() {
      await router.push({
        query: {
          page: this.$route?.page ?? null,
          cterm: this.$route?.query?.cterm ?? null,
          uterm: this.searchTerm,
        },
      });
    },
  },
  computed: {
    title() {
      if (this.customers_detail?.customer?.name) {
        return this.customers_detail.customer.name;
      }
      return "&nbsp;";
    },
    notFound() {
      return this.customers_detail?.status && this.customers_detail.status === 404;
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  white-space: nowrap;
  &--online {
    color: var(--state-online);
  }
}
</style>
